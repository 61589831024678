<template>
  <div class="container">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="section">
        <div class="container">
          <v-row>
            <v-col cols="12" sm="6"> </v-col>
            <v-col cols="12" sm="6"> </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="seller_shipments"
            sort-by="name"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Shipments</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.order_id }}
                </td>
                <td>
                  {{ item.created_at | date_format }}
                </td>
                <td>
                  {{ item.supplier_name }}
                </td>
                <!-- <td>
                  {{item.user_name}}
                </td> -->
                <!-- <td>
                  <span v-if="item.status != null"> {{item.status}} </span>
                  <span v-else> Pending </span>
                </td> -->
                <td>
                  <v-icon
                    small
                    class="mr-2"
                    @click="$router.push('/store/ship-detail/' + item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    dialog: false,
    headers: [
      { text: "Shipment ID" },
      { text: "Order ID" },
      { text: "Date" },
      { text: "Supplier Name" },
      { text: "Actions", sortable: false },
    ],
    orders: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: 0,
      user: 0,
      date: 0,
      total: 0,
      status: 0,
    },
    defaultItem: {
      id: "",
      name: 0,
      user: 0,
      date: 0,
      total: 0,
      status: 0,
    },
  }),
  apollo: {
    // seller_shipments: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderShipments($seller_id: Int) {
    //       seller_shipments(seller_id: $seller_id) {
    //         id
    //         order_id
    //         created_at
    //         status
    //         total
    //         supplier_name
    //         seller_name
    //         seller_user_name
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       seller_id: parseInt(this.$store.state.auth.user.company_relation),
    //     };
    //   },
    // },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.$apollo.queries.seller_shipments.refetch();
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.main-raised {
  margin: -120px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
</style>

<style lang="scss"></style>
